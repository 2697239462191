export const faqConfig = [
  {
    question: "What is GoldPesa (GPX)?",
    answer: [
      "GoldPesa (GPX) is an advanced form of money. A next-generation digital currency that embodies the qualities of gold, powered entirely by code, NOT physical gold.",
      "The most unique feature is the PAWN, a proprietary AI FX algorithm, which generates profits used to continuously buy GPX tokens. This creates constant demand which drives GPX's value higher.",
    ],
  },
  {
    question: "How is GoldPesa different from Bitcoin?",
    answer: [
      "Like Bitcoin, GPX has a hard cap, limited supply, is 100% decentralized, censorship-resistant, and can be easily traded globally.",
      "However, GoldPesa surpasses Bitcoin by being faster, cheaper, deflationary, more liquid, environmentally friendly, and impossible to alter.",
      "What really sets GoldPesa apart from Bitcoin is the Pawn, a proprietary AI FX algorithm that generates profits. These profits are used to continiously buy GPX tokens 24/7, creating constant demand, driving the value of GPX higher.",
    ],
  },
  {
    question: "What is the PAWN?",
    answer: [
      "The PAWN is a proprietary AI FX algorithm, the result of over 20 years of research and development by the GoldPesa team.",
      "Combining digital signal processing, AI, advanced mathematics, and data science, it uses cutting-edge techniques to predict short to medium term market movements with precision.",
    ],
  },
  {
    question: "Is GoldPesa a stablecoin?",
    answer: [
      "No, GoldPesa is not a stablecoin.",
    ],
  },
  {
    question: "How can I buy GoldPesa (GPX)?",
    answer: [
      "GPX tokens will initially be available exclusively on the Uniswap V4 DeFi exchange.",
    ],
  },
  {
    question: "What is GoldPesa Option (GPO)?",
    answer: [
      "The GoldPesa Option (GPO) is a call option that grants holders the right to convert 1 GPO into 1 GPX at any time in the future, subject to the availability of GPX within the PAWN smart contract.",
    ],
  },
  {
    question: "How GPX is accumulated in the PAWN smart contract?",
    answer: [
      "1) Transaction Fees:",
      "0.5% of every GPX transaction is deposited directly into the PAWN smart contract.",
      "2) PAWN Profits:",
      "The PAWN, a proprietary AI FX algorithm, generates profits, which are deposited into the PAWN smart contract. These funds are swapped into GPX hourly and held until all GPO holders are successfully converted to GPX.",
    ],
  },
  {
    question: "Why would I buy GPO instead of GPX?",
    answer: [
      "The GoldPesa Option (GPO) allows larger participants to acquire an allocation of GPX at a discount while minimizing market disruption, making it ideal for institutions, hedge funds, and high-net-worth individuals seeking strategic entry.",
      "GPX, on the other hand, caters to smaller buyers, providing immediate access to GoldPesa's advanced tokenomics. Its price and liquidity are bolstered by The Pawn’s continuous buy-and-recirculate mechanism. ",
      "Together, they offer tailored solutions for both large and small investors.",
    ],
  },
  {
    question: "What makes GoldPesa unique?",
    answer: [
      "GoldPesa's distinct advantage lies in the PAWN, a proprietary algorithm that serves as a highly efficient FX market maker, reducing spreads by up to 50% while generating substantial profits.",
      "PAWN's profits are used to cotniniously buy GPX from Uniswap. This buy mechanism benefits GPX token holders by increasing demand, driving the token's value higher.",
    ],
  },
  {
    question: "Can GPX be changed, altered or forked?",
    answer: [
      "NO! It's impossible!",
      "Unlike Bitcoin, the GoldPesa (GPX) smart contract is 100% immutable.",
      "The rules within the GPX smart contract are permanent and cannot be changed, altered, upgraded, or forked by anyone, including the GoldPesa team.",
    ],
  },
  {
    question: "Is the GoldPesa ecosystem truly DeFi?",
    answer: [
      "Absolutely. Decentralized Finance (DeFi) is at the very heart of the GoldPesa ecosystem. GoldPesa is engineered to redefine the financial landscape through a system governed entirely by immutable smart contracts. These contracts operate independently, free from human intervention, regulatory oversight, or centralized control, ensuring censorship-resistant functionality and seamless tradeability.",
      "Every smart contract within the GoldPesa ecosystem is hard-coded with fixed, unchangeable instructions. Not even the GoldPesa team has the ability to alter, upgrade, or manipulate core functionality inside these contracts in any way. The code is effectively 'locked' and 'the keys thrown away,' creating a transparent and trustless environment where users can transact with absolute confidence.",
      "GoldPesa's commitment to true decentralization eliminates the risks associated with centralized points of failure, empowering users with a system that guarantees autonomy, security, and stability in an ever-evolving financial space.",
    ],
  },
  {
    question: "Does GPX have any fees?",
    answer: [
      "There is a 1% fee anytime GPX is bought, sold or transferred.",
      "Please note, gas fees are separate and required for all GPX transactions.",
    ],
  },
  {
    question: "How is GPX's 1% transfer fee distributed?",
    answer: [
      "0.5% is allocated to the PAWN Smart Contract.",
      "0.5% is allocated to the GoldPesa Mine Smart Contract.",
      "The 1% fee and its distribution are permanently hardcoded into the GPX smart contract, ensuring they can never be changed or altered under ANY circumstance.",
    ],
  },
  {
    question: "What does the PAWN Smart Contract do?",
    answer: [
      "The PAWN smart contract performs four key functions:",
      "1) Collect a 0.5% fee anytime GPX is transferred.",
      "2) Facilitate the conversion of GPO tokens into GPX.",
      "3) Accumulate profits generated by the PAWN in USDC.",
      "4) Continuously buys GPX tokens from Uniswap",
    ],
  },
  {
    question: "What is GoldPesa Mines?",
    answer: [
      "GoldPesa Mines is a revolutionary gamified staking app that allows users to own a part of the GoldPesa ecosystem for just $10.",
      "To join, users purchase a GoldPesa Digger for $10, which is converted into GPX and staked in the GoldPesa Mine. For every 3 GoldPesa Diggers that enter the mine, one lucky user advances deeper into the mine.",
      "The Mine has 10 levels, each holding 10% of its wealth, shared equally among users on that level. Every GPX transaction contributes 0.5% to the Mine, increasing its wealth forever.",
      "GoldPesa Diggers can exit anytime, receiving their initial GPX plus a share of the Mine's wealth based on their level and other miners.",
      "Built on immutable smart contracts, GoldPesa Mines offers a trustless staking opportunity with the potential for lifetime value creation.",
      "Learn more at goldpesamines.com or check out our WhitePaper!",
    ],
  },
  {
    question: "What is the minimum GPX I can buy?",
    answer: [
      "There is no minimum, GPX tokens are capable of being fractionalised up to 18 decimal places.",
    ],
  },
  {
    question: "Why did GoldPesa create GPO?",
    answer: [
      "The GoldPesa Option (GPO) is a specialized token designed to give large investors, early supporters, and long-term holders the opportunity to acquire an allocation of GoldPesa (GPX) at a discount, while also safeguarding GPX’s market stability",
      "With a maximum supply of 100 million tokens, locked liquidity forever, and a fair launch, GPO is secure, tamper-proof, and transparent.",
    ],
  },
  {
    question: "How can I convert my GPO to GPX?",
    answer: [
      "GPO holders can stake their tokens in the GoldPesa Vault. Over time, the staked GPO is systematically converted into GPX whenever GPX becomes available in the PAWN Smart Contract.",
    ],
  },
  {
    question: "What happens to GPO when converted to GPX?",
    answer: [
      "Once GPO is converted into GPX, the original GPO tokens are sold on Uniswap. The resulting USDC proceeds, along with profits from The PAWN (GoldPesa’s AI trading algorithm), are used to continuously purchase GPX. This structured process transfers liquidity from GPO holders to GPX, preventing large sell-offs and reducing concentration risks among major buyers.",
    ],
  },
  {
    question: "Is GoldPesa regulated by any financial authority?",
    answer: [
      "GoldPesa operates independently of financial regulation and has no intention of seeking regulatory approval in any jurisdiction.",
      "At its core, GoldPesa is a pioneer in decentralized finance, built entirely on immutable smart contracts. These contracts have fixed, unalterable instructions that cannot be modified by any individual, organization, or governing authority; including the GoldPesa team itself.",
      "The GoldPesa ecosystem is governed by code, meticulously developed by GoldPesa SRL, a crypto licensed company based in Costa Rica. Every aspect of the ecosystem, from tokenomics to fund flow, has been carefully designed to align with global securities laws, ensuring robust protection for token holders against regulatory uncertainties.",
      "GoldPesa embodies a truly global vision, powered by code and autonomous systems that govern its operations seamlessly. With its architecture locked into place, the ecosystem is free from human interference, paving the way for a future of trustless, decentralized innovation.",
    ],
  },
  {
    question: "What is the expiry date of the GPO options?",
    answer: ["Unlike traditional options, GPO tokens do not expire."],
  },
  {
    question: "How many GPX does the GoldPesa team hold?",
    answer: [
      "None!",
      "GPX is 100% fair-launched, with no tokens allocated to team members or strategic partners. Additionally, no USD has been or will ever be removed from the GPX liquidity protocol.",
    ],
  },
  {
    question: "What blockchain does GoldPesa use?",
    answer: [
      "Base",
    ],
  },
  {
    question: "Can GoldPesa print more tokens?",
    answer: [
      "No, it is impossible!",
      "The GPO and GPX smart contracts are designed with a hard cap of 100 million tokens, ensuring that no additional tokens can ever be created.",
      "The entire GoldPesa ecosystem is 100% decentralized, built on non-upgradable and immutable smart contracts, guaranteeing the permanence and integrity of its token supply.",
    ],
  },
  {
    question: "How does GoldPesa ensure security?",
    answer: [
      "GoldPesa ensures security through cutting-edge blockchain technology, immutable smart contracts, and rigorous audits conducted by trusted firms like Certik.",
      "These measures guarantee transparency, prevent tampering, and build unwavering trust among token holders, solidifying GoldPesa as a secure and reliable decentralized ecosystem.",
    ],
  },
  {
    question: "How many GPO does the GoldPesa team hold?",
    answer: [
      "No member of the GoldPesa team holds more than 100,000 GPO tokens.",
      "As GPO conversion to GPX is limited by the availability of GPX within the PAWN smart contract, this structure ensures stability and confidence for GPX holders. Even if GPO holders convert to GPX and sell, the USDC reserves in the GPX liquidity pools are sufficient to accommodate sell orders without significantly impacting the price.",
    ],
  },
  {
    question: "Is the PAWN's 0.5% fee invested in its algorithm?",
    answer: [
      "No, the GoldPesa team cannot access or divert the 0.5% fee collected by the PAWN smart contract to 'invest' in the PAWN algorithm.",
      "The PAWN smart contract is immutable, with fixed instructions allowing only GPO holders to convert their GPO into GPX on a 1:1 basis. This is the sole mechanism through which GPX can be withdrawn from the PAWN smart contract.",
    ],
  },
  {
    question: "What happens if the PAWN algorithm loses all the money?",
    answer: [
      "If the PAWN algorithm were to lose all its funds, it would temporarily pause the buy process, impacting the demand for GPX. However, this would not affect the GoldPesa ecosystem directly, as GPX is fair-launched, and no funds from GPX transactions can ever leave the ecosystem.",
      "The 1% transaction fee is deposited into immutable smart contracts that solely benefit the ecosystem, ensuring that no GoldPesa token holder’s funds are ever at risk due to the PAWN algorithm.",
    ],
  },
  {
    question: "Is GoldPesa a security?",
    answer: [
      "NO!",
      "To determine if an asset qualifies as a security, the U.S. Securities and Exchange Commission (SEC) uses the Howey Test, which assesses whether a transaction involves an 'investment contract.' For a transaction to be classified as a security under this test, it must meet all four criteria:",
      "1) Investment of Money: There must be an investment of money or equivalent assets.",
      "Users can purchase GPX or GPO tokens, but these are not traditional investments. GPX is a decentralized asset, while GPO is a call option to convert into GPX. Neither token represents ownership or investment in GoldPesa or any company.",
      "2) Common Enterprise: The investment must be part of a common enterprise where returns are pooled or shared.",
      "GoldPesa runs on autonomous, immutable smart contracts with no fund pooling, shared enterprise, or reliance on a central authority for profits. Token holders fully control their assets, with transactions managed by decentralized code.",
      "3) Expectation of Profit: There must be an expectation of profit by the investor.",
      "GPX tokens are decentralized digital assets with utility in their algorithmic ecosystem. Price appreciation may occur due to the buy and redistribute process, driven by immutable code and market dynamics, not by promises or actions from GoldPesa. The PAWN algorithm independently engages in FX market-making with its own funds. Buying and redistributing GPX tokens is voluntary, not guaranteed, and any resulting price increase is a natural outcome of the system’s autonomous operations.",
      "4) Efforts of Others: The profit must primarily come from the efforts of a promoter, team, or third party.",
      "The GoldPesa ecosystem, including the PAWN algorithm and GPX and GPO smart contracts, operates autonomously and immutably. Profits are not derived from the efforts of the GoldPesa team or any third party. The PAWN algorithm generates profits independently, using its own funds, and decides how to use them, including buying and redistributing GPX tokens. These actions are not funded by the ecosystem or its participants, ensuring GoldPesa remains decentralized and independent."
    ],
  },
  {
    question: "Why does the PAWN buy and redistribute GPX tokens if it’s not funded by the GoldPesa ecosystem?",
    answer: [
      "Because its fucking fun!",
    ],
  },
];
